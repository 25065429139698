﻿(function (w, d, $) {
    $(document).ready(function () {
        function initSearchInput() {
            $('.input-box').each(function (i, e) {
                $(this).on('keydown cut paste', 'input[placeholder]', function () {
                    let $field = $(this);
                    let $icon = $(this).parent().find('span.glyphicon-search');
                    let $label = $(this).parent().find('label.placeholder');
                    let initialValue = $field.val();

                    setTimeout(function () {
                        let updatedValue = $field.val();
                        if (updatedValue !== initialValue) {
                            $label.toggleClass('placeholder-state-hidden', updatedValue.length > 0);
                            $icon.toggleClass('placeholder-state-hidden', updatedValue.length > 0);
                        }
                    }, 0);

                });
            });

        }

        let gInt = setInterval(function () {
            if ($(".input-box").length) {
                initSearchInput();
                clearInterval(gInt);
            }
        }, 200);
    });

    // Hide/show the <label> when text is added/removed for IE
    $(document).on('keydown.placeholder cut.placeholder paste.placeholder setValue.placeholder', 'input[placeholder]', function () {
        let $field = $(this);
        let $label = $(this).parent().find('label.placeholder');
        let $icon = $(this).parent().find('span.glyphicon-search');


        let initialValue = $field.val();
        setTimeout(function () {
            let updatedValue = $field.val();
            if (updatedValue !== initialValue) {
                $label.toggleClass('placeholder-state-hidden', updatedValue.length > 0);            }
            $icon.toggleClass('placeholder-state-hidden', updatedValue.length > 0);

        }, 0);
    });


})(window, document, jQuery);
